const userInfoMeEndpoint = (process.env.IDENTITY_BASE_URL || "https://id.preprod.ilbianconero.com") + "/identity/me";
const authorizeEndpoint = (process.env.AUTH_BASE_URL || "https://auth.preprod.ilbianconero.com") + "/oauth2/auth";
const tokenEndpoint = (process.env.AUTH_BASE_URL || "https://auth.preprod.ilbianconero.com") + "/oauth2/token";
const clientId = process.env.CLIENT_ID || "d499096b-744d-4522-a654-5f568d681058";
const clientSecret = process.env.CLIENT_SECRET || "cMc5pOLsoJFhzVajPJejKACTCkYj504Z50QWXTJkaiksNlKjK0OsTHPBDrBt-TIH";

async function generateCodeChallenge(codeVerifier) {
    let digest = await crypto.subtle.digest("SHA-256",
        new TextEncoder().encode(codeVerifier));

    return btoa(String.fromCharCode(...new Uint8Array(digest)))
        .replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_')
}

function generateRandomString(length) {
    let text = "";
    let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (let i = 0; i < length; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return text;
}


exports.getName = () => {
    return 'Jim';
};

exports.startAuth = () => {
    let codeVerifier = generateRandomString(64);
    let state = generateRandomString(16);

    generateCodeChallenge(codeVerifier).then(function (codeChallenge) {

        window.localStorage.setItem("code_verifier", codeVerifier);

        let redirectUri = window.location.href.split('?')[0];
        let args = new URLSearchParams({
            response_type: "code",
            client_id: clientId,
            client_secret: clientSecret,
            code_challenge_method: "S256",
            scope: "offline_access offline openid",
            state: state,
            code_challenge: codeChallenge,
            redirect_uri: redirectUri
        });
        window.location = authorizeEndpoint + "?" + args;
    });
}

exports.logOut = () => {
    window.localStorage.removeItem("auth_user");
    window.localStorage.removeItem("access_token");
    window.localStorage.removeItem("refresh_token");
    window.localStorage.removeItem("expires_in");
    window.localStorage.removeItem("refresh_token_timestamp");
}


exports.getUser = () => {
    let token = window.localStorage.getItem("access_token");
    let xhr = new XMLHttpRequest();

    xhr.onload = function () {
        let response = xhr.response;
        let message;

        if (xhr.status == 200) {
            window.localStorage.setItem("auth_user", JSON.stringify(response));
            window.dispatchEvent(new Event("get-user-success"));

        } else {
            message = "Error get user: " + response.error_description + " (" + response.error + ")";
            console.log(message);
        }

        // document.getElementById("result").innerHTML = message;
    };
    xhr.responseType = 'json';
    xhr.open("GET", userInfoMeEndpoint, true);
    xhr.setRequestHeader('Authorization', `Bearer ${token}`);
    xhr.send();

}
exports.getToken = (code) => {
    let xhr = new XMLHttpRequest();

    xhr.onload = function () {
        let response = xhr.response;
        if (xhr.status == 200) {

            window.localStorage.setItem("access_token", response.access_token);
            window.localStorage.setItem("refresh_token", response.refresh_token);
            window.localStorage.setItem("expires_in", response.expires_in);
            window.localStorage.setItem("refresh_token_timestamp", new Date().getTime().toString());
            module.exports.getUser();

            console.log(response);

        } else {
            console.log("Error get token: " + response.error_description + " (" + response.error + ")");
        }

    };
    xhr.responseType = 'json';
    xhr.open("POST", tokenEndpoint, true);
    xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
    xhr.send(new URLSearchParams({
        client_id: clientId,
        client_secret: clientSecret,
        code_verifier: window.localStorage.getItem("code_verifier"),
        grant_type: "authorization_code",
        scope: "offline_access+offline+openid",
        redirect_uri: location.href.replace(location.search, ''),
        code: code
    }));

}

exports.isLogged = () => {

    let accessToken = window.localStorage.getItem('access_token');

    if (!accessToken) {
        return false;
    }

    let gap = 60;
    let expireIn = parseInt(window.localStorage.getItem('expires_in'));
    let tokenTimestamp = Math.floor(parseInt(window.localStorage.getItem('refresh_token_timestamp')) / 1000);
    let now = Math.floor(new Date().getTime() / 1000);

    let isExpired = (tokenTimestamp + expireIn - gap) < now ;


    console.log('token expire in', expireIn);
    console.log('token timestamp', tokenTimestamp);
    console.log('token now', now);
    console.log('token is expired', isExpired);

    return !isExpired;
}


exports.getRefreshToken = () => {
    return window.localStorage.getItem('refresh_token');
}


exports.refreshToken = () => {
    let refreshToken = module.exports.getRefreshToken();

    console.log('token refresh', refreshToken)


    /*
    *  logout?
    if (!refreshToken) {
        window.dispatchEvent(new Event("refresh-token-fail"));
        return;
    }
    */

    let xhr = new XMLHttpRequest();
    xhr.onload = function () {
        let response = xhr.response;

        if (xhr.status == 200) {

            window.localStorage.setItem("access_token", response.access_token);
            window.localStorage.setItem("refresh_token", response.refresh_token);
            window.localStorage.setItem("expires_in", response.expires_in);
            window.localStorage.setItem("refresh_token_timestamp", new Date().getTime().toString());

            module.exports.getUser();
            console.log(response);


        } else {
            console.log("Error refresh token: " + response.error_description + " (" + response.error + ")");
            window.dispatchEvent(new Event("refresh-token-fail"));
        }

    };

    xhr.responseType = 'json';
    xhr.open("POST", tokenEndpoint, true);
    xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');

    xhr.send(new URLSearchParams({
        client_id: clientId,
        client_secret: clientSecret,
        grant_type: 'refresh_token',
        scope: 'offline_access offline openid',
        refresh_token: refreshToken
    }));

}
